.react-calendar {
  background: transparent !important;
  width: 100% !important;
  border: none !important;
  font-family: "Mabry Pro" !important;
}

.react-calendar__navigation {
  margin-bottom: 0 !important;
}

.react-calendar__navigation button {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.react-calendar__month-view__weekdays {
  font-size: 12px !important;
}

.react-calendar__tile {
  font-size: 16px !important;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: black !important;
}
