body {
  margin: 0;
}

body,
input,
button,
textarea {
  font-family: "Mabry Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  letter-spacing: -0.2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hideChildren--child {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all 50ms ease-in-out;
}

.hideChildren:hover .hideChildren--child {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

@font-face {
  font-family: "Pondre";
  font-weight: 300;
  src: url("./fonts/JMTF_PondreLight.otf");
}

@font-face {
  font-family: "Mabry Pro";
  src: url("./fonts/mabry-pro-regular/mabry-pro-regular.eot");
  src: url("./fonts/mabry-pro-regular/mabry-pro-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/mabry-pro-regular/mabry-pro-regular.woff2") format("woff2"),
    url("./fonts/mabry-pro-regular/mabry-pro-regular.woff") format("woff"),
    url("./fonts/mabry-pro-regular/mabry-pro-regular.ttf") format("truetype"),
    url("./fonts/mabry-pro-regular/mabry-pro-regular.svg#MabryPro-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/* ************************************************************************** */

/*
** mabry-pro-light
*/

@font-face {
  font-family: "Mabry Pro";
  src: url("./fonts/mabry-pro-light/mabry-pro-light.eot");
  src: url("./fonts/mabry-pro-light/mabry-pro-light.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/mabry-pro-light/mabry-pro-light.woff2") format("woff2"),
    url("./fonts/mabry-pro-light/mabry-pro-light.woff") format("woff"),
    url("./fonts/mabry-pro-light/mabry-pro-light.ttf") format("truetype"),
    url("./fonts/mabry-pro-light/mabry-pro-light.svg#MabryPro-Light")
      format("svg");
  font-weight: 300;
  font-style: normal;
}
.loader {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border: 2px rgba(0, 0, 0, 0.1) solid;
  border-top: 2px black solid;
  border-radius: 50%;
  -webkit-animation: spin4 1s infinite linear;
  animation: spin4 1s infinite linear;
}

.loader--s {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 24px;
  width: 24px;
  margin: -12px 0 0 -12px;
  border: 2px rgba(0, 0, 0, 0.1) solid;
  border-top: 2px black solid;
  border-radius: 50%;
  -webkit-animation: spin4 1s infinite linear;
  animation: spin4 1s infinite linear;
}

.loader--xs {
  display: block;
  height: 18px;
  width: 18px;
  border: 2px rgba(0, 0, 0, 0.1) solid;
  border-top: 2px black solid;
  border-radius: 50%;
  -webkit-animation: spin4 1s infinite linear;
  animation: spin4 1s infinite linear;
}

.loader--xxs {
  display: block;
  height: 12px;
  width: 12px;
  margin-left: 10px;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  border-top: 1px black solid;
  border-radius: 50%;
  -webkit-animation: spin4 1s infinite linear;
  animation: spin4 1s infinite linear;
}

.table-header {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

thead {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  background: #fefdf8;
  color: black;
  line-height: 30px;
  height: 30px;
  position: sticky;
  top: 0;
  z-index: 10;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@-webkit-keyframes spin4 {
  from {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes spin4 {
  from {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.react-tabs__tab--selected {
  background: #fefdf8 !important;
}

.react-tabs__tab {
  font-size: 12px;
  padding: 10px 14px !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

.react-tabs__tab--selected {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.react-tabs__tab-list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.navigation a {
  color: currentColor;
  text-decoration: none;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

.navigation .active,
.navigation a:hover {
  border-bottom: 1px solid currentColor;
}

.react-calendar__tile {
  position: relative;
  overflow: visible !important;
}
